import React, { useState } from "react";
import { IMG_API } from "../../api/endPoint";
import PlayerDetailCard from "../Players/PlayerDetailCard";
import PlayerProgressBar from "../Players/PlayerProgressBar";
import { PlayerRadarChart } from "../Players/PlayerRadarChart";
import { AiOutlineRadarChart } from "react-icons/ai";
import { RiBarChartHorizontalFill } from "react-icons/ri";
import { Tooltip } from "@material-tailwind/react";
import AddplayerRating from "../Setting/AddplayerRating";
import { SiCkeditor4 } from "react-icons/si";
import { FcRatings } from "react-icons/fc";
import { BsArrowLeftCircle } from "react-icons/bs";
import get from "../../features/get";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { PlayerComparison } from "./PlayersComparison";
import Loading from "../common/Loading";
import PlayerComparisonBar from "./PlayerComparisonBar";
import NoRecord from "../common/NoRecord";
export default function Index() {
  const [option, setOptiion] = useState(true);
  function handleOption() {
    setOptiion(!option);
  }
  const [playerRating, setPlayerRating] = useState(false);
  const [player, setPlayer] = useState(null);
  const HandlePlayerRatingshow = (e, items) => {
    setPlayerRating(true);
    setPlayer(items);
  };
  const { hash } = useLocation();
  const params = useParams();
  const homePlayer = get?.ShowTeamPlayer(params?.pid);
  const guestPlayer = get?.ShowTeamPlayer(hash[1]);
  const [home_player_id, setHomePlayer] = useState("");
  const [guest_player_id, setGuestPlayer] = useState("");

  let data;
  homePlayer?.map((items) =>
    items?.userid == home_player_id ? (data = items) : null
  );
  let guestdata;
  guestPlayer?.map((items) =>
    items?.userid == guest_player_id ? (guestdata = items) : null
  );
  console.log(data, home_player_id, "homePlayer guestPlayer");
  return (
    <div>
      <NavLink
        to={`/matches/${params?.id}/${params?.pid}/${hash}`}
        type="button"
        className=" mb-4 w-full h-full rounded-md border border-transparent bg-[#0F3A62] px-4 py-4 text-base font-medium text-white shadow-sm hover:bg-[#fff] hover:border-[#0F3A62] hover:text-[#0F3A62] focus:outline-none focus:ring-2 focus:ring-[#0F3A62] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
      >
        <BsArrowLeftCircle className="text-2xl" />
      </NavLink>
      <div className="flex gap-4">
        <select
          onChange={(e) => setHomePlayer(e.target.value)}
          id="capitan"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option selected value="">
            Choose a Player
          </option>
          {homePlayer?.map((items) => (
            <option value={items?.userid}>
              {items?.firstname + " " + items?.lastname}
            </option>
          ))}
        </select>
        <select
          onChange={(e) => setGuestPlayer(e.target.value)}
          id="capitan"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option selected value="">
            Choose a Player
          </option>
          {guestPlayer?.map((items) => (
            <option value={items?.userid}>
              {items?.firstname + " " + items?.lastname}
            </option>
          ))}
        </select>
      </div>
      {home_player_id != "" || guest_player_id != "" ? (
        <div class="flex">
          <div
            onClick={(e) => e.stopPropagation()}
            class="relative transform w-full rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 "
          >
            <div class="bg-white ">
              <div
                className={
                  option ? "flex items-center justify-between" : "flex"
                }
              >
                {home_player_id != "" ? (
                  <div className="bg-gray-900">
                    <div className="">
                      <PlayerDetailCard items={data} />
                    </div>
                    <div className="flex justify-center items-center gap-2">
                      {!option ? (
                        <AiOutlineRadarChart
                          onClick={handleOption}
                          className="text-white text-center text-2xl cursor-pointer"
                        />
                      ) : (
                        <RiBarChartHorizontalFill
                          onClick={handleOption}
                          className="text-white text-center text-3xl cursor-pointer"
                        />
                      )}
                      {data?.userid == null ? (
                        <Tooltip content="Player Rating">
                          <div
                            onClick={(e) => HandlePlayerRatingshow(e, data)}
                            className="p-2 flex justify-center cursor-pointer"
                          >
                            <FcRatings className="text-3xl" />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Update Player Rating">
                          <div
                            onClick={(e) => HandlePlayerRatingshow(e, data)}
                            className="p-2 flex justify-center cursor-pointer"
                          >
                            <SiCkeditor4 className="text-3xl text-[#fff]" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                ) : (
                  <Loading />
                )}
                {option ? (
                  <div className="px-[70px]">
                    <div className="h-[450px] w-[450px]">
                      <PlayerComparison data={data} guestplayer={guestdata} />
                    </div>
                  </div>
                ) : (
                  <PlayerComparisonBar data={data} guestplayer={guestdata} />
                )}{" "}
                {guest_player_id != "" ? (
                  <div className="bg-gray-900">
                    <div className="">
                      <PlayerDetailCard items={guestdata} />
                    </div>

                    <div className="flex justify-center items-center gap-2">
                      {!option ? (
                        <AiOutlineRadarChart
                          onClick={handleOption}
                          className="text-white text-center text-2xl cursor-pointer"
                        />
                      ) : (
                        <RiBarChartHorizontalFill
                          onClick={handleOption}
                          className="text-white text-center text-3xl cursor-pointer"
                        />
                      )}
                      {data?.userid == null ? (
                        <Tooltip content="Player Rating">
                          <div
                            onClick={(e) => HandlePlayerRatingshow(e, data)}
                            className="p-2 flex justify-center cursor-pointer"
                          >
                            <FcRatings className="text-3xl" />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip content="Update Player Rating">
                          <div
                            onClick={(e) => HandlePlayerRatingshow(e, data)}
                            className="p-2 flex justify-center cursor-pointer"
                          >
                            <SiCkeditor4 className="text-3xl text-[#fff]" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoRecord title={"Please Select Player"} />
      )}

      {playerRating ? (
        <AddplayerRating data={player} modal={setPlayerRating} />
      ) : (
        ""
      )}
    </div>
  );
}
