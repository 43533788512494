import React from "react";
import { useParams } from "react-router-dom";
import get from "../../features/get";
import Capitan from "./Capitan";
import EditTeam from "./EditTeam";
import Manager from "./Manager";
// import Players from "./Players";
import TeamCapitan from "./TeamCapitan";
import TeamInfo from "./TeamInfo";
import TeamManager from "./TeamManager";
import Players from "../../pages/Players/Index";

export default function TeamDetail() {
  const params = useParams();

  const data = get?.Showteam(params.id);

  const teamPlayers = get?.ShowTeamPlayer(params?.id);
  console.log(data, "TeamDetail");
  return (
    <div className="bg-[#f5f5f5]">
      <TeamInfo data={data} />
      <Players match={false} />
      {/* <div className=" flex gap-5">
        {/* <Players data={teamPlayers} /> */}
      {/* <Manager data={TeamDetail} />
        <Capitan data={TeamDetail} />
        <EditTeam data={TeamDetail} /> */}
      {/* </div> */}
    </div>
  );
}
