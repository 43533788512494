import React from "react";
import Setting from "../../components/Setting";

export default function Index() {
  return (
    <div>
      <Setting />
    </div>
  );
}
