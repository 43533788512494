import axios from "axios";
import React from "react";
import { useState } from "react";
import { API_BASE_URL } from "../../api/endPoint";
import get from "../../features/get";

export default function EditUser({ data, modal }) {
  function handleClose() {
    modal(false);
  }
  const positions = get?.Getpositions();
  const teams = get?.Getteam();
  const [firstname, setFirstName] = useState(data?.firstname);
  const [lastname, setLastName] = useState(data?.lastname);
  const [posId, setPosition] = useState(data?.posId);
  const [teamId, setTeam] = useState(data?.teamId);
  const [email, setEmail] = useState(data?.email);
  const [number, setNumber] = useState(data?.number);
  const [phone_number_1, setPhoneNumber] = useState(data?.phone_number_1);
  const [phone_number_2, setOtherPhoneNumber] = useState(data?.phone_number_2);
  const [image, setImage] = useState({ preview: "", data: "" });

  const handleChange = function loadFile(e) {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };
    setImage(img);
  };
  let user = {
    firstname,
    lastname,
    posId,
    teamId,
    email,
    number,
    phone_number_1,
    phone_number_2,
    avatar: image?.data,
  };
  const handleCreate = (e) => {
    e.preventDefault();
    axios
      .put(`${API_BASE_URL}update/user/${data?.userid}`, user, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => console.log(res, "back end response"))
      .catch((err) => console.log(err, "error back end response"));
  };
  console.log(user, data, "users");
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={handleClose}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div className="">
              <h1 className="py-2 px-5">Update User</h1>
            </div>
            <form onSubmit={handleCreate}>
              <div className="grid justify-center">
                <div className="inline-block relative justify-center flex-col gap-2 mb-2">
                  <div id="camera--icon">
                    <span className="text-[#797979] text-[12px] text-center">
                      {image?.preview == "" ? "Please select Avatar" : ""}
                      <input
                        name="Select File"
                        type="file"
                        onChange={handleChange}
                        accept="image/*"
                      ></input>
                      {image?.preview !== "" ? (
                        <img
                          className="max-w-[200px] h-[200px] rounded-md"
                          src={image?.preview}
                          alt="image description"
                        />
                      ) : (
                        <img
                          className="max-w-[200px] h-[200px] rounded-md "
                          src="https://flowbite.com/docs/images/examples/image-1@2x.jpg"
                          alt="image description"
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="grid grid-cols-12 gap-3">
                  <div className="col-span-6">
                    {" "}
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      First Name
                    </label>
                    <input
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div className="col-span-6">
                    {" "}
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Last Name
                    </label>
                    <input
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      type="text"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <div className={`col-span-6`}>
                    <label
                      for="capitan"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select playing position
                    </label>
                    <select
                      value={posId}
                      onChange={(e) => setPosition(e.target.value)}
                      id="capitan"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected value={null}>
                        Choose a Position
                      </option>
                      {positions?.map((items) => (
                        <option value={items?.id}>{items?.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-span-6">
                    <label
                      for="Manager"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Select Team
                    </label>

                    <select
                      value={teamId}
                      onChange={(e) => setTeam(e.target.value)}
                      id="Manager"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected value={null}>
                        Choose a Team
                      </option>
                      {teams?.map((items) => (
                        <option value={items?.id}>{items?.name}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-span-6">
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email
                    </label>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Number
                    </label>
                    <input
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      type="number"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Number"
                      required
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Phone Number
                    </label>
                    <input
                      value={phone_number_1}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      type="number"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="phone number"
                      required
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Other Phone Number
                    </label>
                    <input
                      value={phone_number_2}
                      onChange={(e) => setOtherPhoneNumber(e.target.value)}
                      type="number"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="other"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#67e8f9] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#22d3ee] focus:outline-none focus:ring-2 focus:ring-[#22d3ee] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Update
                </button>
                <button
                  onClick={handleClose}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
