import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { IMG_API } from "../../api/endPoint";
import get from "../../features/get";

export default function TeamMatches() {
  const params = useParams();
  const data = get.ShowTeamMatchs(params?.id);
  console.log(data, " team matches");
  const team = get.Getteam();
  let teams = [];
  // data?.map((items) =>
  //   team.map((team) => (items.team_id == team.id ? teams.push(team) : ""))
  // );
  console.log(teams, data, "match team");
  return (
    <div className="grid grid-cols-12  gap-4">
      {data?.map((matches) => (
        <div className="col-span-4 border shadow-lg">
          <div className="grid grid-cols-6 gap-3 items-center">
            <NavLink
              to={`/matches/${matches?.id}/${matches?.team_id}/#${
                matches?.competitors_id == null
                  ? matches?.other_team
                  : matches?.competitors_id
              }`}
              className="col-span-4"
            >
              <div className="flex justify-between items-center  border-r-4 border-[#0F3A62] p-2">
                <div className="grid gap-2">
                  <div className="flex items-center gap-1">
                    <img
                      src={`${IMG_API}/${matches?.team_logo}`}
                      className="w-[40px] h-[40px] rounded-full"
                    />
                    <div className="text-[14px] font-semibold">
                      {matches?.team_name}
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    <img
                      src={`${IMG_API}/${matches?.other_team_logo}`}
                      className="w-[40px] h-[40px] rounded-full"
                    />
                    <div className="text-[14px] font-semibold">
                      {matches?.other_team_name}
                    </div>
                  </div>
                </div>
                <div className="grid gap-3">
                  <div className="">{matches?.team_result}</div>
                  <div className="">{matches?.competitor_result}</div>
                </div>
              </div>
            </NavLink>
            <div className=" col-span-2">
              <div className="grid gap-2">
                {matches?.isEnd == 1 ? "FT" : "Match Date"}
              </div>
              <div className="grid gap-2">{matches?.match_date}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
