import React, { useState } from "react";
import { API_BASE_URL, IMG_API } from "../../api/endPoint";
import Competitor from "../Matches/Competitor";
import TeamCapitan from "./TeamCapitan";
import TeamManager from "./TeamManager";
import { IoMdAddCircle, IoMdCreate } from "react-icons/io";
import { RiTimerFill } from "react-icons/ri";
import TeamPercentage from "./TeamPercentage";
import axios from "axios";
import get from "../../features/get";
import { useLocation, useParams } from "react-router-dom";
export default function TeamInfo({ data, match, other }) {
  const [create, setCreate] = useState(false);
  const HandleCreate = () => {
    setCreate(true);
  };
  console.log(data, "team info data");
  let teamresult = {};
  let params = useParams();
  let { hash } = useLocation();
  const team = get?.ShowTeamPercentage(params.teamid);
  const otherteam = get?.ShowTeamPercentage(hash[1]);
  const teamtable = get.ShowTeamTable(params.teamid);
  const othertable = get.ShowTeamTable(hash[1]);
  let result;
  function percentage(wins, losses, draws) {
    return (~~wins / (~~wins + ~~losses + ~~draws)) * 100;
  }
  if (data?.team_result > data?.competitor_result) {
    result = {
      team_win: team?.win + 1,
      team_draw: team?.draw,
      team_loss: team?.loss,

      team_table_GF: teamtable?.goals_for + data?.team_result,
      team_table_GA: teamtable?.goals_against + data?.competitor_result,
      team_table_GD:
        teamtable?.goals_for + data?.team_result - data?.competitor_result,
      other_win: otherteam?.win,
      other_loss: otherteam?.loss + 1,
      other_draw: otherteam?.draw,
      other_table_GF: othertable?.goals_for + data?.competitor_result,
      other_table_GA: othertable?.goals_against + data?.team_result,
      other_table_GD:
        othertable?.goals_for + data?.competitor_result - data?.team_result,
      team_id: params.teamid,
      otherteam: hash[1],
      team_result: data?.team_result,
      competitor_result: data?.competitor_result,
      wining_percentage: ~~percentage(team?.win + 1, team?.loss, team?.draw),
      other_wining_percentage: ~~percentage(
        otherteam?.win,
        otherteam?.loss + 1,
        otherteam?.draw
      ),
      points: teamtable?.points + 3,
      otherpoints: othertable?.points,
      match_played: teamtable?.match_played + 1,
      other_match_played: othertable?.match_played + 1,
    };
  } else if (data?.team_result < data?.competitor_result) {
    result = {
      team_win: team?.win,
      team_draw: team?.draw,
      team_loss: team?.loss + 1,
      team_table_GF: teamtable?.goals_for + data?.team_result,
      team_table_GA: teamtable?.goals_against + data?.competitor_result,
      team_table_GD:
        teamtable?.goals_for + data?.team_result - data?.competitor_result,
      other_win: otherteam?.win + 1,
      other_loss: otherteam?.loss,
      other_draw: otherteam?.draw,
      other_table_GF: othertable?.goals_for + data?.competitor_result,
      other_table_GA: othertable?.goals_against + data?.team_result,
      other_table_GD:
        othertable?.goals_for + data?.competitor_result - data?.team_result,
      team_id: params.teamid,
      otherteam: hash[1],
      team_result: data?.team_result,
      competitor_result: data?.competitor_result,
      wining_percentage: ~~percentage(team?.win, team?.loss + 1, team?.draw),
      other_wining_percentage: ~~percentage(
        otherteam?.win + 1,
        otherteam?.loss,
        otherteam?.draw
      ),
      points: teamtable?.points,
      otherpoints: othertable?.points + 3,
      match_played: teamtable?.match_played + 1,
      other_match_played: othertable?.match_played + 1,
    };
  } else {
    result = {
      team_win: team?.win,
      team_draw: team?.draw + 1,
      team_loss: team?.loss,
      team_table_GF: teamtable?.goals_for + data?.team_result,
      team_table_GA: teamtable?.goals_against + data?.competitor_result,
      team_table_GD:
        teamtable?.goals_for + data?.team_result - data?.competitor_result,
      other_win: otherteam?.win,
      other_loss: otherteam?.loss,
      other_draw: otherteam?.draw + 1,
      other_table_GF: othertable?.goals_for + data?.competitor_result,
      other_table_GA: othertable?.goals_against + data?.team_result,
      other_table_GD:
        othertable?.goals_for + data?.competitor_result - data?.team_result,
      team_id: params.teamid,
      team_id: params.teamid,
      otherteam: hash[1],
      team_result: data?.team_result,
      competitor_result: data?.competitor_result,
      wining_percentage: ~~percentage(team?.win, team?.loss, team?.draw + 1),
      other_wining_percentage: ~~percentage(
        otherteam?.win,
        otherteam?.loss,
        otherteam?.draw + 1
      ),
      points: teamtable?.points + 1,
      otherpoints: othertable?.points + 1,
      match_played: teamtable?.match_played + 1,
      other_match_played: othertable?.match_played + 1,
    };
  }
  function HandleFulltime(e) {
    e.preventDefault();
    axios
      .put(`${API_BASE_URL}matchresult/fulltime/${params?.id}`, result)
      .then((res) => console.log(res, "back end response"))
      .catch((err) => console.log(err, "error back end response"));
  }

  console.log(team, "team and other");

  console.log(result, "result");
  return (
    <div className="grid gap-2">
      <div className="">
        <div className="flex bg-[#e2e2e2] items-center justify-between">
          <div className="flex  justify-center  p-2">
            {data?.logo !== null ? (
              <div className="w-[80px] h-[80px] ">
                <img
                  className="flex justify-center"
                  alt={data?.name}
                  src={`${IMG_API}/${data?.logo}`}
                />
              </div>
            ) : (
              <div className="w-[80px] h-[80px] bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full ">
                {data?.name[0]}
              </div>
            )}
            <div className="">
              <div className="text-[#0F3A62] text-center text-[21px]">
                {data?.name} ({data?.abbreviation})
              </div>
              <div className="flex justify-start gap-10 bg-[#e2e2e2] items-center">
                <div className="">
                  <div className="text-md text-[#0F3A62] text-center font-semibold ">
                    {data?.win == null ? 0 : data?.win}
                  </div>
                  <div className="text-[#0F3A62]  font-semibold text-sm">
                    {data?.win <= 1 ? "Win" : "Wins"}
                  </div>
                </div>
                <div className="">
                  <div className="text-md text-[#0F3A62] text-center  font-semibold ">
                    {data?.draw == null ? 0 : data?.draw}
                  </div>
                  <div className="text-[#0F3A62]   font-semibold text-sm">
                    {data?.draw <= 1 ? "Draw" : "Draws"}
                  </div>
                </div>
                <div className="">
                  <div className="text-md text-[#0F3A62] text-center font-semibold ">
                    {data?.loss == null ? 0 : data?.loss}
                  </div>
                  <div className="text-[#0F3A62]  font-semibold text-sm">
                    {data?.loss <= 1 ? "Loss" : "Losses"}
                  </div>
                </div>
                {data?.teampercent_id == null ? (
                  <IoMdAddCircle
                    className="text-[#0F3A62] text-xl"
                    onClick={HandleCreate}
                  />
                ) : (
                  <IoMdCreate onClick={HandleCreate} />
                )}
              </div>
            </div>
          </div>
          {match ? (
            <div className="grid justify-center">
              <div className=" flex gap-5">
                <div className="text-[40px] text-[#0F3A62]  font-bold ">
                  {data?.team_result}
                </div>
                <div className="text-[40px] text-[#0F3A62]  font-bold ">VS</div>
                <div className="text-[40px] text-[#0F3A62]  font-bold ">
                  {data?.competitor_result}
                </div>
              </div>
              <div className="flex justify-center cursor-pointer">
                {data?.isEnd == 0 ? (
                  <RiTimerFill
                    onClick={HandleFulltime}
                    className="text-[40px] text-center text-[#0F3A62]  font-bold "
                  />
                ) : (
                  <div className="text-[30px] text-center text-[#0F3A62] ">
                    FT
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {match ? (
            <Competitor other={other} data={data} />
          ) : (
            <>
              <TeamManager data={data} />
              <TeamCapitan data={data} />
            </>
          )}
        </div>
      </div>
      {create ? <TeamPercentage data={data} modal={setCreate} /> : ""}
    </div>
  );
}
