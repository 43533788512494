import axios from "axios";
import React, { useState } from "react";
import { API_BASE_URL, IMG_API } from "../../api/endPoint";

export default function MatchResult({ modal, data }) {
  const [team_result, setTeam] = useState(
    data?.team_result == null ? 0 : data?.team_result
  );
  const [competitor_result, setCompetitor] = useState(
    data?.competitor_result == null ? 0 : data?.competitor_result
  );

  let matcheResults = {
    team_result,
    competitor_result,
    match_id: data?.id,
  };
  const handleCreate = (e) => {
    e.preventDefault();
    axios
      .post(
        `${API_BASE_URL}${
          data?.matchresult_id == null
            ? "create/matchresult"
            : `update/matchresult/${data?.matchresult_id}`
        }`,
        matcheResults
      )
      .then((res) => console.log(res, "back end response"))
      .catch((err) => console.log(err, "error back end response"));
  };
  function handleClose() {
    modal(false);
  }
  console.log(matcheResults, "mmmmm");
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={handleClose}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex data-end justify-center p-4 text-center sm:data-center sm:p-0">
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div className="">
              <h1 className="py-2 px-5">
                {data?.matchresult_id == null
                  ? "Add Match Result"
                  : "Update Match Result"}
              </h1>
            </div>
            <form onSubmit={handleCreate}>
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="grid grid-cols-12 gap-3 items-center">
                  <div className="flex"></div>
                  <div className="col-span-5">
                    <div className=" ">
                      {data?.logo !== null ? (
                        <div className="flex justify-center">
                          <img
                            alt={data?.team_name}
                            src={`${IMG_API}/${data?.team_logo}`}
                            className="w-[80px] h-[80px] rounded-full"
                          />
                        </div>
                      ) : (
                        <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center data-center  rounded-full w-[80px] h-[80px]">
                          {data?.team_name[0]}
                        </div>
                      )}
                    </div>
                    <label
                      for="name"
                      className="flex justify-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {data?.team_name}
                    </label>
                    <input
                      value={team_result}
                      onChange={(e) => setTeam(e.target.value)}
                      type="text"
                      competitors
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="text-[#0F3A62] text-2xl font-bold col-span-1">
                    VS
                  </div>
                  {data?.competitor_id != null ? (
                    <div className="col-span-5">
                      <div className=" ">
                        {data?.competitor_logo !== null ? (
                          <div className="flex justify-center">
                            <img
                              alt={data?.competitor_name}
                              src={`${IMG_API}/${data?.competitor_logo}`}
                              className="w-[80px] h-[80px] rounded-full"
                            />
                          </div>
                        ) : (
                          <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center data-center  rounded-full w-[80px] h-[80px]">
                            {data?.competitor_name[0]}
                          </div>
                        )}
                      </div>
                      <label
                        for="name"
                        className=" flex justify-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {data?.competitor_name}
                      </label>
                      <input
                        value={competitor_result}
                        onChange={(e) => setCompetitor(e.target.value)}
                        type="text"
                        competitors
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  ) : (
                    <div className="col-span-5">
                      <div className=" ">
                        {data?.other_team_logo !== null ? (
                          <div className="flex justify-center">
                            <img
                              alt={data?.other_team_name}
                              src={`${IMG_API}/${data?.other_team_logo}`}
                              className="w-[80px] h-[80px] rounded-full"
                            />
                          </div>
                        ) : (
                          <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center data-center  rounded-full w-[80px] h-[80px]">
                            {data?.other_team_name[0]}
                          </div>
                        )}
                      </div>
                      <label
                        for="name"
                        className=" flex justify-center mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        {data?.other_team_name}
                      </label>
                      <input
                        value={competitor_result}
                        onChange={(e) => setCompetitor(e.target.value)}
                        type="text"
                        competitors
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#67e8f9] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#22d3ee] focus:outline-none focus:ring-2 focus:ring-[#22d3ee] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {data?.matchresult_id == null ? "Create" : "Update"}
                </button>
                <button
                  onClick={handleClose}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
