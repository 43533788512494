import logo from "./logo.svg";
import "./styles/style.css";
import Layout from "./layout/index";
import Home from "./pages/Home";
import Players from "./pages/Players/Index";
import Teams from "./pages/Teams";
import Setting from "./pages/Setting";
import Competitors from "./pages/Competitors";
import Matches from "./pages/Matches";
import TeamDetail from "./components/Teams/TeamDetail";
import { Routes, Route, BrowserRouter, Router } from "react-router-dom";
import PlayersCard from "./components/Players/PlayersCard";
import MatchDetail from "./components/Matches/MatchDetail";
import Comparison from "./components/Comparison/index.jsx";
import Table from "./components/Table/";
import TeamStatus from "./components/Table/TeamStatus";
function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="teams">
            <Route index element={<Teams />} />
            <Route path="/teams/:id" element={<TeamDetail />} />
            <Route path="/teams/players/:id" element={<Players />} />
          </Route>
          <Route path="/setting" element={<Setting />} />
          <Route path="/Players" element={<PlayersCard />} />
          <Route path="/competitors" element={<Competitors />} />
          <Route path="/matches">
            <Route index element={<Matches />} />
            <Route path="/matches/:id/:teamid" element={<MatchDetail />} />
          </Route>
          <Route path="/comparison/:id/:pid">
            <Route index element={<Comparison />} />
          </Route>
          <Route path="/table">
            <Route index element={<Table />} />
            <Route path="/table/matches/:id" element={<TeamStatus />} />
          </Route>
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
