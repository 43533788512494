import { IMG_API } from "../../api/endPoint";

export default function TeamManager({ data }) {
  return (
    <div className="  w-[435px]">
      <div className="flex justify-center px-0 py-0">
        <div className="bg-[#0F3A62]  mb-1 ">
          <div className="text-[14px] text-white px-[30px] py-[2px]">M</div>
        </div>
      </div>
      {data?.managerAvatar !== null ? (
        <div className="flex justify-center items-center gap-4">
          <div className="flex ">
            <div className="w-[60px] h-[60px] border border-[#0F3A62] rounded-full  ">
              <img
                alt="players"
                src={`${IMG_API}/${data?.managerAvatar}`}
                className="rounded-full"
              />
            </div>
          </div>
          <div className="">
            <div className="text-[12px] text-[#0F3A62]">Name</div>
            <div className="text-[14px] text-[#0F3A62]">
              {`${data?.managerfname} ${data?.managerlname}`}
            </div>
          </div>
          <div className="">
            <div className="text-[12px] text-[#0F3A62]">Position</div>
            <div className="text-[14px] text-[#0F3A62] font-bold">Manager</div>
          </div>
          <div className="">
            <div className="text-[12px] text-[#0F3A62]">WIN Percentage</div>
            <div className="text-[14px] text-[#0F3A62] font-bold">56%</div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="w-[60px] h-[60px] bg-gray-500/50 flex justify-center items-center border border-[#0F3A62] rounded-full">
            <div className="bg- text-center text-[28px] text-bold">
              {data?.managerfname != null ? data?.managerfname[0] : ""}
              {data?.managerlname != null ? data?.managerlname[0] : ""}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
