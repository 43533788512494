import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function PlayerComparison({ data, guestplayer }) {
  console.log(guestplayer, "guestplayer");
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scale: {
      ticks: {
        beginAtZero: true,
        max: 100,
      },
    },
  };
  var chartOptions = {
    scale: {
      gridLines: {
        color: "black",
        lineWidth: 3,
      },
      angleLines: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stepSize: 20,
      },
      pointLabels: {
        fontSize: 18,
        fontColor: "green",
      },
    },
    legend: {
      position: "left",
    },
  };
  const datas = {
    labels: ["Pace", "Defending", "Heading", "Passing", "Dribble", "Agility"],
    datasets: [
      {
        label: `${data?.firstname}`,
        data: [
          data?.pace,
          data?.defending,
          data?.heading,
          data?.passing,
          data?.dribbling,
          data?.agility,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      },
      {
        label: `${
          guestplayer?.firstname == undefined ? "" : guestplayer?.firstname
        } `,
        data: [
          guestplayer?.pace,
          guestplayer?.defending,
          guestplayer?.heading,
          guestplayer?.passing,
          guestplayer?.dribbling,
          guestplayer?.agility,
        ],
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgb(54, 162, 235)",
        pointBackgroundColor: "rgb(54, 162, 235)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(54, 162, 235)",
      },
    ],
  };
  console.log(datas, "pppppp");
  return <Radar options={chartOptions} data={datas} />;
}
