import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import IEWhite from "../assets/SVG/White.png";

export default function Index(props) {
  return (
    <div className="grid gap-4">
      <div className="bg-[#0F3A62]">
        <div className="flex justify-between items-center p-4 ">
          <div className="flex gap-1 items-center ">
            <img alt="logo" src={IEWhite} className="w-[50px] h-[50px]"></img>
            <div className="text-white text-xl">FootBall Team</div>
          </div>
          <ul className="flex gap-4">
            <li className="-mb-px mr-1">
              <NavLink
                activeClassName="active"
                className="text-white  py-2 px-4  font-semibold"
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="-mb-px mr-1">
              <NavLink
                activeClassName="active"
                className="text-white  py-2 px-4  font-semibold"
                to="/table"
              >
                Table
              </NavLink>
            </li>
            <li className="mr-1">
              <NavLink
                className="text-white  py-2 px-4  font-semibold"
                to="teams"
              >
                Teams
              </NavLink>
            </li>

            <li className="mr-1">
              <NavLink
                className="text-white active:bg-black  py-2 px-4  font-semibold"
                to="competitors"
                active
              >
                Competitors
              </NavLink>
            </li>
            <li className="mr-1">
              <NavLink
                className="text-white active:bg-black  py-2 px-4  font-semibold"
                to="matches"
                active
              >
                Matches
              </NavLink>
            </li>
            <li className="mr-1">
              <NavLink
                activeClassName="active:text-blue-500"
                className="text-white  py-2 px-4  font-semibold"
                to="setting"
                active
              >
                Setting
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="px-[40px]">{props?.children}</div>
    </div>
  );
}
