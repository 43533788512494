import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IMG_API } from "../../api/endPoint";
import get from "../../features/get";
import Search from "../common/Search";
import AddCompetitor from "./AddCompetitor";

export default function Index() {
  const [search, setSearch] = useState("");
  const [create, setCreate] = useState(false);
  const HandleCreateshow = (e, items) => {
    setCreate(true);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const competitors = [];
  const data = get.GetCompetitors();
  data?.filter((items) => {
    if (search === "") {
      return competitors.push(items);
    } else if (
      items?.competitor_name?.toLowerCase().includes(search?.toLowerCase())
    ) {
      return competitors.push(items);
    }
  });
  return (
    <div>
      <div className="flex gap-3 items-baseline mb-3">
        <Search handleSearch={handleSearch} />
        <button
          onClick={HandleCreateshow}
          type="button"
          className=" w-full h-[40px] rounded-md border border-transparent bg-[#0F3A62] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#fff] hover:border-[#0F3A62] hover:text-[#0F3A62] focus:outline-none focus:ring-2 focus:ring-[#0F3A62] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Create Competitor
        </button>
      </div>
      <div className="grid grid-cols-12 gap-5">
        {competitors?.map((items) => (
          <div className="col-span-4">
            <div className="bg-[#fff] shadow-lg  hover:shadow-[#0f3a6279] border border-gray-500">
              <div className=" p-4">
                {items?.logo !== null ? (
                  <div className="flex justify-center">
                    <img
                      alt={items?.competitor_name}
                      src={`${IMG_API}/${items?.competitor_logo}`}
                      className="w-[80px] h-[80px] rounded-full"
                    />
                  </div>
                ) : (
                  <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                    {items?.name[0]}
                  </div>
                )}

                <div className="grid justify-center">
                  <div className="text-md font-bold  text-[#0F3A62] ">
                    {items?.competitor_name}
                  </div>
                </div>
              </div>

              <div className="flex justify-start gap-10 bg-[#e2e2e2] px-4">
                <div className="flex gap-1 items-center">
                  <div className="text-md text-[#0F3A62] text-center  font-semibold ">
                    <img
                      alt={items?.competitor_name}
                      src={`${IMG_API}/${items?.representative_avatar}`}
                      className="w-[50px] h-[50px] rounded-full"
                    />
                  </div>
                  <div className="flex gap-10">
                    <div>
                      <div className="text-[#0F3A62]  text-sm">Full name</div>
                      <div className="text-[#0F3A62] font-semibold  text-sm">
                        {items?.representative_name}
                      </div>
                    </div>
                    <div>
                      <div className="text-[#0F3A62]  text-sm">Email</div>
                      <div className="text-[#0F3A62] font-semibold  text-sm">
                        {items?.representative_email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {create ? <AddCompetitor modal={setCreate} /> : ""}
    </div>
  );
}
