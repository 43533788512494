import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IMG_API } from "../../api/endPoint";
import get from "../../features/get";

export default function Index({ id }) {
  const table = get.GetTable();
  let count = 0;
  const teamMatch = useNavigate();
  function HandleTeamMatch(e, id) {
    teamMatch(`/table/matches/${id}`);
  }
  return (
    <div>
      <div className="flex flex-col items-center justify-center ">
        <div className="flex flex-col mt-6">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden sm:rounded-lg">
                <table className="min-w-full text-sm text-gray-400">
                  <thead className="bg-gray-800 text-xs uppercase font-medium">
                    <tr>
                      <th></th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        Club
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        MP
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        W
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        D
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        L
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        GF
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        GA
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        GD
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        Pts
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left tracking-wider"
                      >
                        Last 5
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-gray-800">
                    {table?.map((items) => (
                      <tr
                        onClick={
                          id != items.team_id
                            ? (e) => HandleTeamMatch(e, items?.team_id)
                            : ""
                        }
                        className={
                          id != items.team_id
                            ? "bg-black bg-opacity-20 cursor-pointer"
                            : "bg-white bg-opacity-100 text-black cursor-pointer"
                        }
                      >
                        <td className="pl-4">{(count += 1)}</td>
                        <td className="flex px-6 py-4 whitespace-nowrap">
                          <img
                            className="w-5"
                            src={`${IMG_API}/${items?.team_logo}`}
                            alt=""
                          />
                          <span className="ml-2 font-medium">
                            {items?.team_name}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {items?.match_played}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {items?.win}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {items?.draw}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {items?.loss}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {items?.goals_for}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {" "}
                          {items?.goals_against}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {items?.goals_difference}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {items?.points}
                        </td>
                        <td className="flex px-6 py-4 whitespace-nowrap">
                          <svg
                            className="w-4 fill-current text-green-600"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <svg
                            className="w-4 fill-current text-green-600"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <svg
                            className="w-4 fill-current text-green-600"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <svg
                            className="w-4 fill-current text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <svg
                            className="w-4 fill-current text-green-600"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
