import { useEffect, useState } from "react";
import React from "react";
import { API_BASE_URL } from "../api/endPoint";
import axios from "axios";

export function Getpositions() {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}index/position`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function Getteam() {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}index/teams`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function Getusers() {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}index/users`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function Showteam(id) {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}show/team/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function ShowTeamPlayer(id) {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}show/teamplayers/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function GetCompetitors() {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}index/competitors`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function GetMatches() {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}index/matches`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function GetTable() {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}index/table`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function ShowMatch(id) {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}show/match/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function ShowTeamPercentage(id) {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}show/teampercentage/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function ShowTeamTable(id) {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}show/table/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}
export function ShowTeamMatchs(id) {
  const [data, setData] = useState();
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}show/teammatchs/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return data;
}

export default {
  Getpositions,
  Getteam,
  Showteam,
  Getusers,
  ShowTeamPlayer,
  GetCompetitors,
  GetMatches,
  ShowMatch,
  ShowTeamPercentage,
  GetTable,
  ShowTeamTable,
  ShowTeamMatchs,
};
