import { IMG_API } from "../../api/endPoint";
import get from "../../features/get";

export default function TeamCapitan({ data }) {
  const users = get.Getusers();

  let user;
  users?.map((items) =>
    items?.player_id === data?.capitanId ? (user = items) : []
  );
  console.log(users, user, "hhaahah");
  return (
    <div className="  w-[438px]">
      <div className="flex justify-center px-0 py-0">
        <div className="bg-[#0F3A62]  mb-1 ">
          <div className="text-[14px] text-white px-[30px] py-[2px]">C</div>
        </div>
      </div>
      {data?.captainAvatar !== null ? (
        <div className="flex justify-center items-center gap-4 ">
          <div className="flex ">
            <div className="w-[60px] h-[60px] border border-[#0F3A62] rounded-full  ">
              <img
                alt="players"
                src={`${IMG_API}/${data?.captainAvatar}`}
                className="rounded-full w-[60px] h-[60px]"
              />
            </div>
          </div>
          <div className="">
            <div className="text-[12px] text-[#0F3A62]">Name</div>
            <div className="text-[14px] text-[#0F3A62]">
              {`${data?.captainname} ${data?.captainlname}`}
            </div>
          </div>
          <div className="">
            <div className="text-[12px] text-[#0F3A62]">Position</div>
            <div className="text-[14px] text-[#0F3A62] font-bold">
              {user?.position}
            </div>
          </div>
          <div className="">
            <div className="text-[12px] text-[#0F3A62]">Over All</div>
            <div className="text-[14px] text-[#0F3A62] font-bold">
              {user?.overall}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex gap-3 items-center">
          <div className="w-[60px] h-[60px] bg-gray-500/50 flex justify-center items-center border border-[#0F3A62] rounded-full">
            <div className="bg- text-center text-[28px] text-bold">
              {data?.captainname != null ? data?.captainname[0] : ""}
              {data?.captainlname != null ? data?.captainlname[0] : ""}
            </div>
          </div>
          <div className="flex gap-6">
            <div className="">
              <div className="text-[12px] text-[#0F3A62]">Name</div>
              <div className="text-[14px] text-[#0F3A62]">
                {`${data?.captainname} ${data?.captainlname}`}
              </div>
            </div>
            <div className="">
              <div className="text-[12px] text-[#0F3A62]">Position</div>
              <div className="text-[14px] text-[#0F3A62] font-bold">
                {user?.position}
              </div>
            </div>
            <div className="">
              <div className="text-[12px] text-[#0F3A62]">Over All</div>
              <div className="text-[14px] text-[#0F3A62] font-bold">
                {user?.overall}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
