import React, { useState } from "react";
import Tab from "../common/Tab";
import TeamMatches from "./TeamMatches";
import Table from "./index";
import TableTab from "../common/Tabletab";
import Players from "../../pages/Players/Index";
import { useParams } from "react-router-dom";

export default function TeamStatus() {
  const params = useParams();
  const CompanyTabData = [
    {
      label: "Matches",
      content: <TeamMatches />,
    },
    {
      label: "Standing",
      content: <Table id={params?.id} />,
    },
    {
      label: "Players",
      content: <Players />,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (idx) => {
    setActiveTabIndex(idx);
  };
  return (
    <div className="grid gap-5">
      <TableTab
        activeTab={activeTabIndex}
        data={CompanyTabData}
        handlechange={handleTabChange}
      />

      {CompanyTabData[activeTabIndex]?.content}
    </div>
  );
}
