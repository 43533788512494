import React, { useState } from "react";
import { IMG_API } from "../../api/endPoint";
import PlayerDetailCard from "./PlayerDetailCard";
import PlayerProgressBar from "./PlayerProgressBar";
import { PlayerRadarChart } from "./PlayerRadarChart";
import { AiOutlineRadarChart } from "react-icons/ai";
import { RiBarChartHorizontalFill } from "react-icons/ri";
import { Tooltip } from "@material-tailwind/react";
import AddplayerRating from "../Setting/AddplayerRating";
import { SiCkeditor4 } from "react-icons/si";
import { FcRatings } from "react-icons/fc";
export default function PlayerDetail({ data, modal }) {
  function handleClose() {
    modal(false);
  }
  let avatar = [];
  const [option, setOptiion] = useState(true);
  function handleOption() {
    setOptiion(!option);
  }
  const [playerRating, setPlayerRating] = useState(false);
  const [player, setPlayer] = useState(null);
  const HandlePlayerRatingshow = (e, items) => {
    setPlayerRating(true);
    setPlayer(items);
  };
  return (
    <div
      class="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={handleClose}
    >
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full data-end justify-center text-center sm:data-center sm:p-0">
          <div
            onClick={(e) => e.stopPropagation()}
            class="relative transform w-[980px] rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 "
          >
            <div class="bg-white ">
              <div className={option ? "flex items-center" : "flex"}>
                <div className="bg-gray-900">
                  <div className="p-[59px]">
                    <PlayerDetailCard items={data} />
                  </div>
                  <div className="flex justify-center items-center gap-2">
                    {!option ? (
                      <AiOutlineRadarChart
                        onClick={handleOption}
                        className="text-white text-center text-2xl cursor-pointer"
                      />
                    ) : (
                      <RiBarChartHorizontalFill
                        onClick={handleOption}
                        className="text-white text-center text-3xl cursor-pointer"
                      />
                    )}
                    {data?.userid == null ? (
                      <Tooltip content="Player Rating">
                        <div
                          onClick={(e) => HandlePlayerRatingshow(e, data)}
                          className="p-2 flex justify-center cursor-pointer"
                        >
                          <FcRatings className="text-3xl" />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip content="Update Player Rating">
                        <div
                          onClick={(e) => HandlePlayerRatingshow(e, data)}
                          className="p-2 flex justify-center cursor-pointer"
                        >
                          <SiCkeditor4 className="text-3xl text-[#fff]" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>

                {option ? (
                  <div className="px-[70px]">
                    <div className="h-[450px] w-[450px]">
                      <PlayerRadarChart data={data} />
                    </div>
                  </div>
                ) : (
                  <PlayerProgressBar data={data} />
                )}
              </div>

              {/* <div className="bg-[#3f9ce82e] flex gap-0 justify-between">
                <div className="px-3 py-2">
                  <div className="text-black text-[12px]">Age</div>
                  <div className="text-black text-[18px]">{data?.age}</div>
                  <div className="text-black text-[12px]">Position</div>
                  <div className="text-black text-[18px]">{data?.position}</div>
                  <div className="text-black text-[12px]">Number of played</div>
                  <div className="text-black text-[18px]">52</div>
                  <div className="text-black text-[12px]">
                    Number of man of the match
                  </div>
                  <div className="text-black text-[18px]">5</div>
                </div>
                <div className="px-3 py-2">
                  <div className="text-black text-[12px]">Assist</div>
                  <div className="text-black text-[18px]">5</div>
                  <div className="text-black text-[12px]">Goal</div>
                  <div className="text-black text-[18px]">5</div>
                  <div className="text-black text-[12px]">
                    Number of <span style={{ color: "yellow" }}>yellow</span>{" "}
                    card
                  </div>
                  <div className="text-black text-[18px]">5</div>
                  <div className="text-black text-[12px]">
                    Number of <span style={{ color: "red" }}>red</span> card
                  </div>
                  <div className="text-black text-[18px]">5</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {playerRating ? (
        <AddplayerRating data={player} modal={setPlayerRating} />
      ) : (
        ""
      )}
    </div>
  );
}
