import React, { useState } from "react";
import Tab from "../common/Tab";
import Positions from "./Positions";
import Users from "./Users";

export default function Index() {
  const CompanyTabData = [
    {
      label: "Positions",
      content: <Positions />,
    },
    {
      label: "Users",
      content: <Users />,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (idx) => {
    setActiveTabIndex(idx);
  };
  return (
    <dic className="grid grid-cols-12 gap-3">
      <div className="col-span-2 bg-[#0F3A62] h-24 py-1 px-8 flex flex-col  justify-start mt-3">
        <div className="flex justify-between items-baseline">
          <Tab
            activeTab={activeTabIndex}
            data={CompanyTabData}
            handlechange={handleTabChange}
          />
        </div>
      </div>
      <div className="col-span-10">
        {CompanyTabData[activeTabIndex]?.content}{" "}
      </div>
    </dic>
  );
}
