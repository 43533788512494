import React, { useState } from "react";
import get from "../../features/get";
import { MdModeEdit, MdDelete } from "react-icons/md";
import Search from "../common/Search";
import NoRecord from "../common/NoRecord";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import DeleteModal from "../common/DeleteModal";
import EditPosition from "./EditPosition";
import { Tooltip } from "@material-tailwind/react";
import AddPosition from "./AddPostion";
import AddUser from "./AddUser";
import { FcRatings } from "react-icons/fc";
import { SiCkeditor4 } from "react-icons/si";
import AddplayerRating from "./AddplayerRating";
import EditUser from "./EditUser";

export default function Users() {
  const data = get?.Getusers();
  let users = [];
  const [search, setSearch] = useState("");
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [playerRating, setPlayerRating] = useState(false);
  const [player, setPlayer] = useState(null);
  const [user, setUser] = useState(null);
  const HandleCreateshow = () => {
    setCreate(true);
  };
  const HandleUpdateshow = (e, items) => {
    setUpdate(true);
    setUser(items);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const HandlePlayerRatingshow = (e, items) => {
    setPlayerRating(true);
    setPlayer(items);
  };
  data?.filter((items) => {
    if (search === "") {
      return users.push(items);
    } else if (
      items?.firstname?.toLowerCase().includes(search) ||
      items?.lastname?.toLowerCase().includes(search)
    ) {
      return users.push(items);
    }
  });
  console.log(data, "data data");
  return (
    <div className="">
      <div className="flex gap-3 items-baseline mb-3">
        <Search handleSearch={handleSearch} />
        <button
          onClick={HandleCreateshow}
          type="button"
          className=" w-full h-[40px] rounded-md border border-transparent bg-[#0F3A62] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#fff] hover:border-[#0F3A62] hover:text-[#0F3A62] focus:outline-none focus:ring-2 focus:ring-[#0F3A62] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Create User
        </button>
      </div>
      {users?.length !== 0 ? (
        <div className="grid grid-cols-12  gap-3">
          {users?.map((items) => (
            <div className="col-span-4">
              <div className="bg-[#3f9ce82e] shadow-lg w-[310px] ">
                <div className="flex justify-between p-5">
                  <div className="grid gap-3">
                    <div className="text-[12px]">Name</div>

                    <div className="text-[16px]">{`${items?.firstname} ${items?.lastname}`}</div>
                  </div>
                  <div className="text-[21px] grid gap-3">
                    <MdModeEdit onClick={(e) => HandleUpdateshow(e, items)} />
                    <MdDelete
                      // onClick={(e) => HandleDeleteshow(e, items)}
                      className="text-[#ef4444]"
                    />
                  </div>
                </div>
                {items?.userid == null ? (
                  <Tooltip content="Player Rating">
                    <div
                      onClick={(e) => HandlePlayerRatingshow(e, items)}
                      className="p-2 flex justify-center cursor-pointer"
                    >
                      <FcRatings className="text-xl" />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip content="Update Player Rating">
                    <div
                      onClick={(e) => HandlePlayerRatingshow(e, items)}
                      className="p-2 flex justify-center cursor-pointer"
                    >
                      <SiCkeditor4 className="text-xl text-[#207cd5]" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
      {create ? (
        <AddUser modal={setCreate} />
      ) : playerRating ? (
        <AddplayerRating data={player} modal={setPlayerRating} />
      ) : update ? (
        <EditUser data={user} modal={setUpdate} />
      ) : (
        ""
      )}
    </div>
  );
}
