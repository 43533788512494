import React from "react";
import loadingGif from "../../assets/Loading_2.gif";

export default function Loading() {
  return (
    <div className="grid justify-center ml-[50px]">
      <div className="flex justify-center">
        <img src={loadingGif} className="w-[100px] h-[100px]" alt="loading" />
      </div>
      <div className="text-[18px] text-[#0F3A62]">Waiting....</div>
    </div>
  );
}
