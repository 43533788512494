import React from "react";
import { useParams } from "react-router-dom";
import Players from "../../components/Players/Index";
import get from "../../features/get";

export default function Index({ id, match, otherteam }) {
  const params = useParams();
  const teamPlayers = get?.ShowTeamPlayer(
    match == true ? params?.teamid : params?.id
  );
  console.log(params?.id, match, "data");
  return (
    <div>
      <Players otherteam={otherteam} match={match} data={teamPlayers} />
    </div>
  );
}
