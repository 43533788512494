import React from "react";
import Teams from "../../components/Teams/Teams";

export default function Index() {
  return (
    <div>
      <Teams />
    </div>
  );
}
