import React from "react";

export default function PlayerProgressBar({ data }) {
  return (
    <div className="w-full">
      <div className="bg-gray-900 text-center text-[18px] font-bold text-white p-4">
        Attribute
      </div>
      <div className="mt-[17px]">
        <div className="w-full  px-5 py-3 ">
          <div className="mb-3">Pace(PAC)</div>
          <div className=" flex gap-5">
            <div>{data?.pace}</div>
            <div className="w-full bg-[#272a33] h-[22px]">
              <div
                className="bg-[#4999c1] h-[22px] text-xs font-medium text-blue-100 text-center p-0.5 leading-none"
                style={{ width: `${data?.pace}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className=" bg-[#e2e2e2] w-full  px-5 py-3 ">
          <div className="mb-3">Shooting(SHO)</div>
          <div className=" flex gap-5">
            <div>{data?.shooting}</div>
            <div className="w-full bg-[#272a33] h-[22px]">
              <div
                className="bg-[#4999c1] h-[22px] text-xs font-medium text-blue-100 text-center p-0.5 leading-none"
                style={{ width: `${data?.shooting}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="w-full  px-5 py-3 ">
          <div className="mb-3">Passing(PAS)</div>
          <div className=" flex gap-5">
            <div>{data?.passing}</div>
            <div className="w-full bg-[#272a33] h-[22px]">
              <div
                className="bg-[#4999c1] h-[22px] text-xs font-medium text-blue-100 text-center p-0.5 leading-none"
                style={{ width: `${data?.passing}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className=" bg-[#e2e2e2] w-full  px-5 py-3 ">
          <div className="mb-3">Dribbling(DRI)</div>
          <div className=" flex gap-5">
            <div>{data?.dribbling}</div>
            <div className="w-full bg-[#272a33] h-[22px]">
              <div
                className="bg-[#4999c1] h-[22px] text-xs font-medium text-blue-100 text-center p-0.5 leading-none"
                style={{ width: `${data?.dribbling}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="w-full  px-5 py-3 ">
          <div className="mb-3">Defending(DEF)</div>
          <div className=" flex gap-5">
            <div>{data?.defending}</div>
            <div className="w-full bg-[#272a33] h-[22px]">
              <div
                className="bg-[#4999c1] h-[22px] text-xs font-medium text-blue-100 text-center p-0.5 leading-none"
                style={{ width: `${data?.defending}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className=" bg-[#e2e2e2] w-full  px-5 py-3 ">
          <div className="mb-3">Physical(PHY)</div>
          <div className=" flex gap-5">
            <div>{data?.physical}</div>
            <div className="w-full bg-[#272a33] h-[22px]">
              <div
                className="bg-[#4999c1] h-[22px] text-xs font-medium text-blue-100 text-center p-0.5 leading-none"
                style={{ width: `${data?.physical}%` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
