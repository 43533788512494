import React from "react";
import { FcSearch } from "react-icons/fc";

export default function Search(props) {
  return (
    <div className=" relative  text-gray-600 ">
      <input
        className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        type="search"
        name="search"
        placeholder="Search"
        onChange={props?.handleSearch}
      />
      <button type="submit" className="relative right-[30px] top-1 mt-5 mr-4">
        <FcSearch />
      </button>
    </div>
  );
}
