import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IMG_API } from "../../api/endPoint";
import emptyProfile from "../../assets/SVG/empty-profile1.png";
import get from "../../features/get";
import NoRecord from "../common/NoRecord";
import AddUser from "../Setting/AddUser";
import PlayerDetail from "./PlayerDetail";
export default function OtherTeam() {
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const [detail, setDetail] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const { hash } = useLocation();
  const data = get?.ShowTeamPlayer(hash[1]);
  const handlePlayerDetail = () => {
    setDetail(true);
  };
  let players = [];

  data?.filter((items) => {
    if (search === "") {
      return players.push(items);
    } else if (
      items?.firstname?.toLowerCase().includes(search.toLowerCase()) ||
      items?.lastname?.toLowerCase().includes(search.toLowerCase())
    ) {
      return players.push(items);
    }
  });
  const [create, setCreate] = useState(false);
  const HandleCreateshow = () => {
    setCreate(true);
  };
  const params = useParams();
  return (
    <div>
      {players?.length != 0 ? (
        <div className="wrapper">
          {players?.map((items) => (
            <div
              onClick={(e) => {
                handlePlayerDetail(e);
                setPlayerData(items);
              }}
              class="fut-player-card"
            >
              <div class="player-card-top">
                <div class="player-master-info">
                  <div class="player-rating">
                    <span>{items?.overall == null ? 0 : items?.overall}</span>
                  </div>
                  <div class="player-position">
                    <span>{items?.position}</span>
                  </div>
                  {/* <div class="player-nation">
                        <img src={IElogo} alt="IE" draggable="false" />
                      </div> */}
                  <div class="player-club">
                    <img
                      src={`${IMG_API}/${items?.teamlogo}`}
                      alt="Barcelona"
                      draggable="false"
                    />
                  </div>
                </div>
                <div class="player-picture">
                  {items?.avatar != null ? (
                    <img
                      src={`${IMG_API}/${items?.avatar}`}
                      alt={items?.firstname}
                      draggable="false"
                    />
                  ) : (
                    <img
                      src={emptyProfile}
                      alt={items?.firstname}
                      draggable="false"
                    />
                  )}

                  {/* <div class="player-extra">
                        <span>4*SM</span>
                        <span>4*WF</span>
                      </div> */}
                </div>
              </div>

              <div class="player-card-bottom">
                <div class="player-info">
                  <div class="player-name">
                    <span>{items?.firstname}</span>
                  </div>

                  <div class="player-features">
                    <div class="player-features-col">
                      <span>
                        <div class="player-feature-value">
                          {items?.pace == null ? 0 : items?.pace}
                        </div>
                        <div class="player-feature-title">PAC</div>
                      </span>
                      <span>
                        <div class="player-feature-value">
                          {" "}
                          {items?.shooting == null ? 0 : items?.shooting}
                        </div>
                        <div class="player-feature-title">SHO</div>
                      </span>
                      <span>
                        <div class="player-feature-value">
                          {items?.passing == null ? 0 : items?.passing}
                        </div>
                        <div class="player-feature-title">PAS</div>
                      </span>
                    </div>
                    <div class="player-features-col">
                      <span>
                        <div class="player-feature-value">
                          {items?.dribbling == null ? 0 : items?.dribbling}
                        </div>
                        <div class="player-feature-title">DRI</div>
                      </span>
                      <span>
                        <div class="player-feature-value">
                          {items?.defending == null ? 0 : items?.defending}
                        </div>
                        <div class="player-feature-title">DEF</div>
                      </span>
                      <span>
                        <div class="player-feature-value">
                          {items?.physical == null ? 0 : items?.physical}
                        </div>
                        <div class="player-feature-title">PHY</div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
      {create ? <AddUser teamid={params?.id} modal={setCreate} /> : ""}
      {detail ? <PlayerDetail data={playerData} modal={setDetail} /> : ""}
    </div>
  );
}
