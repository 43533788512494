import React from "react";
import noDataSvg from "../../assets/SVG/players.svg";

export default function NoRecord({ title }) {
  return (
    <div className="grid justify-center">
      <img className="w-[200px] h-[200px]" src={noDataSvg} alt="" />
      <div className="text-black text-[16px] text-center">
        {title == undefined ? "No record has been found" : title}
      </div>
    </div>
  );
}
