import React from "react";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export function PlayerRadarChart({ data }) {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scale: {
      ticks: {
        beginAtZero: true,
        max: 100,
      },
    },
  };
  var chartOptions = {
    scale: {
      gridLines: {
        color: "black",
        lineWidth: 3,
      },
      angleLines: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
        min: 0,
        max: 100,
        stepSize: 20,
      },
      pointLabels: {
        fontSize: 18,
        fontColor: "green",
      },
    },
    legend: {
      position: "left",
    },
  };
  const datas = {
    labels: ["Pace", "Defending", "Heading", "Passing", "Dribble", "Agility"],
    datasets: [
      {
        label: "Player Rating",
        data: [
          data?.pace,
          data?.defending,
          data?.heading,
          data?.passing,
          data?.dribbling,
          data?.agility,
        ],
        backgroundColor: "#4999c1",
        borderColor: "#000",
        borderWidth: 1,
      },
    ],
  };
  console.log(datas, "pppppp");
  return <Radar options={chartOptions} data={datas} />;
}
