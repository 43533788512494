import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IMG_API } from "../../api/endPoint";
import get from "../../features/get";
import Search from "../common/Search";
import AddMatches from "./AddMatches";
import AddCompetitor from "./AddMatches";
import { BiFootball } from "react-icons/bi";
import { Tooltip } from "@material-tailwind/react";
import MatchResult from "./MatchResult";
export default function Index() {
  const [search, setSearch] = useState("");
  const [create, setCreate] = useState(false);
  const [createresult, setCreateResult] = useState(false);
  const [MatchResults, setMatchResult] = useState(null);
  const HandleCreateshow = (e, items) => {
    setCreate(true);
  };
  const HandleCreateResult = (e, items) => {
    setCreateResult(true);
    setMatchResult(items);
  };
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const matches = [];
  const data = get.GetMatches();
  data?.filter((items) => {
    if (search === "") {
      return matches.push(items);
    } else if (
      items?.team_abb?.toLowerCase().includes(search?.toLowerCase()) ||
      items?.competitor_name?.toLowerCase().includes(search?.toLowerCase())
    ) {
      return matches.push(items);
    }
  });
  console.log(data, "temaaaa");

  return (
    <div>
      <div className="flex gap-3 items-baseline mb-3">
        <Search handleSearch={handleSearch} />
        <button
          onClick={HandleCreateshow}
          type="button"
          className=" w-full h-[40px] rounded-md border border-transparent bg-[#0F3A62] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#fff] hover:border-[#0F3A62] hover:text-[#0F3A62] focus:outline-none focus:ring-2 focus:ring-[#0F3A62] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Create Matches
        </button>
      </div>
      <div className="grid grid-cols-12 gap-5">
        {matches?.map((items) => (
          <div className="col-span-4 bg-[#fff] shadow-lg  hover:shadow-[#0f3a6279] border border-gray-500">
            <NavLink
              to={`/matches/${items?.id}/${items?.team_id}/#${
                items?.competitors_id == null
                  ? items?.other_team
                  : items?.competitors_id
              }`}
            >
              <div className="">
                <div className="flex items-center justify-center">
                  <div className=" p-3">
                    {items?.team_logo !== null ? (
                      <div className="flex justify-center">
                        <img
                          alt={items?.team_name}
                          src={`${IMG_API}/${items?.team_logo}`}
                          className="w-[80px] h-[80px] rounded-full"
                        />
                      </div>
                    ) : (
                      <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                        {items?.team_name[0]}
                      </div>
                    )}
                    <div className="gird items-center gap-2 ">
                      <div className="grid">
                        <div className="text-xl font-bold text-[#0F3A62] w-[138px]">
                          {" "}
                          {items?.team_abb}
                        </div>
                        <div className="text-xs text-[#0F3A62]  font-semibold">
                          {items?.team_name}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-[30px] text-[#0F3A62]  font-bold flex gap-2">
                    <div>{items?.team_result}</div>
                    <div>VS</div>
                    <div>{items?.competitor_result}</div>
                  </div>
                  {items?.competitors_id != null ? (
                    <div className=" p-3">
                      {items?.competitor_logo !== null ? (
                        <div className="flex justify-center">
                          <img
                            alt={items?.competitor_name}
                            src={`${IMG_API}/${items?.competitor_logo}`}
                            className="w-[80px] h-[80px] rounded-full"
                          />
                        </div>
                      ) : (
                        <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                          {items?.competitor_name != null
                            ? items?.competitor_name[0]
                            : items?.other_team_name[0]}
                        </div>
                      )}
                      <div className="gird items-center gap-2 ">
                        <div className="grid">
                          <div className="text-1xl font-bold text-[#0F3A62] w-[138px]">
                            {items?.competitor_name}
                          </div>
                          <div className="text-xs text-[#0F3A62]  font-semibold">
                            {items?.representative_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" p-3">
                      {items?.other_team_logo !== null ? (
                        <div className="flex justify-center">
                          <img
                            alt={items?.competitor_name}
                            src={`${IMG_API}/${items?.other_team_logo}`}
                            className="w-[80px] h-[80px] rounded-full"
                          />
                        </div>
                      ) : (
                        <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                          {items?.competitors_id != null
                            ? items?.competitor_name[0]
                            : items?.other_team_name[0]}
                        </div>
                      )}
                      <div className="gird items-center gap-2 ">
                        <div className="grid">
                          <div className="text-1xl font-bold text-[#0F3A62] w-[138px]">
                            {items?.other_team_abb}
                          </div>
                          <div className="text-xs text-[#0F3A62]  font-semibold">
                            {items?.other_team_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </NavLink>
            <div className=" bg-[#e2e2e2] px-4">
              <div className="flex justify-between items-center">
                <div>
                  <div className="text-[#0F3A62]  text-sm">Match Date</div>
                  <div className="text-[#0F3A62] font-semibold  text-sm">
                    {items?.match_date}
                  </div>
                </div>
                {items?.matchresult_id == null ? (
                  <Tooltip content={"Add Match Result"}>
                    <div
                      className="text-[#0F3A62]  text-2xl courser-pointer"
                      onClick={(e) => HandleCreateResult(e, items)}
                    >
                      <BiFootball />
                    </div>
                  </Tooltip>
                ) : (
                  <Tooltip
                    content={
                      items?.isEnd == 1 ? "Full Time" : "Upadate Match Result"
                    }
                  >
                    <div
                      className="text-[#0F3A62]  text-2xl courser-pointer"
                      onClick={(e) =>
                        items?.isEnd == 1 ? "" : HandleCreateResult(e, items)
                      }
                    >
                      {items?.isEnd == 1 ? "FT" : <BiFootball />}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      {create ? <AddMatches modal={setCreate} /> : ""}
      {createresult ? (
        <MatchResult data={MatchResults} modal={setCreateResult} />
      ) : (
        ""
      )}
    </div>
  );
}
