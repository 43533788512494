import React from "react";
import { useLocation, useParams } from "react-router-dom";
import get from "../../features/get";
import TeamInfo from "../Teams/TeamInfo";
import Players from "../../pages/Players/Index";

export default function MatchDetail() {
  const params = useParams();
  const { hash } = useLocation();

  const data = get?.ShowMatch(params.id);
  const TeamDetail = data;
  const teamPlayers = get?.ShowTeamPlayer(params?.teamid);
  console.log(params.id, TeamDetail, "tteamPlayers");
  const otherdata = get?.Showteam(hash[1]);
  console.log(otherdata, "otherdata");
  return (
    <div className="bg-[#f5f5f5]">
      <TeamInfo other={otherdata} match={true} data={TeamDetail} />
      <Players otherteam={data?.competitor_id} match={true} />
      {/* <div className=" flex gap-5">
        {/* <Players data={teamPlayers} /> */}
      {/* <Manager data={TeamDetail} />
        <Capitan data={TeamDetail} />
        <EditTeam data={TeamDetail} /> */}
      {/* </div> */}
    </div>
  );
}
