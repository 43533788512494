import axios from "axios";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { API_BASE_URL, IMG_API } from "../../api/endPoint";
import get from "../../features/get";
import Search from "../common/Search";
import AddTeam from "./AddTeam";

export default function Teams() {
  const [show, setShow] = useState(false);
  //   {
  //     id: 1,
  //     team_name: "Software as a service",
  //     abbreviation: "SaaS",
  //     team_leader: "Daniel Alemu",
  //     number_p: "6",
  //     logo: "https://cdn-icons-png.flaticon.com/512/4784/4784532.png",
  //     players: [
  //       {
  //         id: 1,
  //         name: "Kiruble Simachew",
  //         age: "30",
  //         position: "CD",
  //         img: "https://bc3-production-assets-cdn.basecamp-static.com/3974505/people/BAhpBBXpQAI=--8bf290b3b64bc749cc0dde62a3a4a55ab0e6e7af/avatar?v=1",
  //       },
  //       {
  //         id: 2,
  //         name: "Abraham Dulla",
  //         age: "28",
  //         position: "CM",
  //         img: "https://bc3-production-assets-cdn.basecamp-static.com/3974505/people/BAhpBLHxOQI=--67523325bed7732d9eb55e07eca29d87c3009b6b/avatar?v=1",
  //       },
  //       {
  //         id: 3,
  //         name: "Surafel Kifle",
  //         age: "34",
  //         position: "SS",
  //         img: null,
  //       },
  //       {
  //         id: 4,
  //         name: "Zemen Teshager",
  //         age: "40",
  //         position: "Manager",
  //         img: "https://bc3-production-assets-cdn.basecamp-static.com/3974505/people/BAhpBAvdOQI=--86e3652b955d35d7b0d2a26db27f0e750004dfaa/avatar?v=1",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     team_name: "Modern Data center and cloud",
  //     abbreviation: "MDCC",
  //     team_leader: "Mastewal Betigst",
  //     number_p: "5",
  //     logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLtwkon62og87E1FPmClR9Z-f14SrUqkUSnKsglJGGut25wdudk1eAEaqYNZQ4Nx8PGFA&usqp=CAU",
  //     players: [],
  //   },
  //   {
  //     id: 3,
  //     team_name: "IT infrastructure",
  //     abbreviation: "ITF",
  //     team_leader: "Bilal Wujira",
  //     number_p: "7",
  //     logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREn-glBL50kuYCqJNRTN8vUUU-PvalAPHT2k2XmYzPw65xhPIWvV0fSvmXhmZYcAoEaF0&usqp=CAU",
  //     players: [],
  //   },
  //   {
  //     id: 4,
  //     team_name: "Enterprise Network Solution",
  //     abbreviation: "ENS",
  //     team_leader: "Mesay Werku",
  //     number_p: "3",
  //     logo: "https://www.ienetworksolutions.com/wp-content/uploads/2020/11/ITF.png",
  //     players: [],
  //   },
  //   {
  //     id: 5,
  //     team_name: "Enterprise Network Solution",
  //     abbreviation: "ENS",
  //     team_leader: "Mesay Werku",
  //     number_p: "3",
  //     logo: null,
  //     players: [],
  //   },
  // ];
  function Handelshow() {
    setShow(true);
  }
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const teams = [];
  const data = get.Getteam();
  data?.filter((items) => {
    if (search === "") {
      return teams.push(items);
    } else if (items?.name?.toLowerCase().includes(search?.toLowerCase())) {
      return teams.push(items);
    }
  });
  const [pos, setPos] = useState(null);
  const [deletes, setDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [create, setCreate] = useState(false);
  const HandleDeleteshow = (e, items) => {
    setDelete(true);
    setPos(items);
  };
  const HandleUpdateshow = (e, items) => {
    setUpdate(true);
    setPos(items);
  };
  const HandleCreateshow = (e, items) => {
    setCreate(true);
  };
  return (
    <div>
      <div className="flex gap-3 items-baseline mb-3">
        <Search handleSearch={handleSearch} />
        <button
          onClick={HandleCreateshow}
          type="button"
          className=" w-full h-[40px] rounded-md border border-transparent bg-[#0F3A62] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#fff] hover:border-[#0F3A62] hover:text-[#0F3A62] focus:outline-none focus:ring-2 focus:ring-[#0F3A62] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Create Team
        </button>
      </div>
      <div className="grid grid-cols-12 gap-5">
        {teams?.map((items) => (
          <NavLink
            to={`/teams/${items?.id}`}
            className="lg:col-span-4 sm:col-span-12 md:col-span-4 xm:col-span-12"
          >
            <div className="bg-[#fff] shadow-lg  hover:shadow-[#0f3a6279] border border-gray-500">
              <div className=" p-4">
                {items?.logo !== null ? (
                  <div className="flex justify-center">
                    <img
                      alt={items?.team_name}
                      src={`${IMG_API}/${items?.logo}`}
                      className="w-[80px] h-[80px] rounded-full"
                    />
                  </div>
                ) : (
                  <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                    {items?.name[0]}
                  </div>
                )}
                <div className="flex justify-between">
                  <div className="gird items-center gap-2 ">
                    <div className="grid">
                      <div className="text-2xl font-bold text-[#0F3A62] w-[138px]">
                        {" "}
                        {items?.abbreviation}
                      </div>
                      <div className="text-xs text-[#0F3A62]  font-semibold">
                        {items?.name}
                      </div>
                    </div>
                  </div>
                  <div className="gird items-center gap-2 ">
                    <div className="grid">
                      <div className="text-2xl text-right font-bold text-[#0F3A62] w-[138px]">
                        {items?.wining_precentage == null
                          ? 0
                          : items?.wining_precentage}
                        %
                      </div>
                      <div className="text-xs text-[#0F3A62] text-right  font-semibold">
                        Winning Percentage
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-center gap-10 bg-[#e2e2e2]">
                <div className="grid">
                  <div className="text-md text-[#0F3A62] text-center  font-semibold ">
                    {items?.win == null ? 0 : items?.win}
                  </div>
                  <div className="text-[#0F3A62]  font-semibold text-sm">
                    Win
                  </div>
                </div>
                <div className="grid">
                  <div className="text-md text-[#0F3A62]  text-center  font-semibold ">
                    {items?.draw == null ? 0 : items?.draw}
                  </div>
                  <div className="text-[#0F3A62]  font-semibold text-sm">
                    Draw
                  </div>
                </div>
                <div className="grid">
                  <div className="text-md text-[#0F3A62]  text-center font-semibold ">
                    {items?.loss == null ? 0 : items?.loss}
                  </div>
                  <div className="text-[#0F3A62]  font-semibold text-sm">
                    Loss
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        ))}
      </div>
      {create ? <AddTeam modal={setCreate} /> : ""}
    </div>
  );
}
