import React from "react";
import { IMG_API } from "../../api/endPoint";
import get from "../../features/get";
import OtherTeam from "../Teams/OtherTeam";

export default function Competitor({ data, other }) {
  return (
    <div>
      {data?.competitor_id != null ? (
        <div className="flex  justify-center items-center gap-3  p-2">
          {data?.logo !== null ? (
            <div className="w-[80px] h-[80px] ">
              <img
                className="flex justify-center"
                alt={data?.competitor_name}
                src={`${IMG_API}/${data?.competitor_logo}`}
              />
            </div>
          ) : (
            <div className="w-[80px] h-[80px] bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full ">
              {data?.name[0]}
            </div>
          )}
          <div className="">
            <div className="text-[#0F3A62] text-center text-[21px]">
              {data?.competitor_name} ({data?.representative_name})
            </div>
            <div className="">
              <div className="flex justify-start gap-10 bg-[#e2e2e2]">
                {data?.representative_phone_1 != null ? (
                  <div className="">
                    <div className="text-md text-[#0F3A62] text-center font-semibold ">
                      {data?.representative_phone_1}
                    </div>
                    <div className="text-[#0F3A62]  font-semibold text-sm">
                      Phone Number
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {data?.representative_phone_2 != null ? (
                  <div className="">
                    <div className="text-md text-[#0F3A62] text-center  font-semibold ">
                      {data?.representative_phone_2}
                    </div>
                    <div className="text-[#0F3A62]   font-semibold text-sm">
                      Other
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <OtherTeam data={other} />
      )}
    </div>
  );
}
