import React from "react";
import { IMG_API } from "../../api/endPoint";
import Competitor from "../Matches/Competitor";
import TeamCapitan from "./TeamCapitan";
import TeamManager from "./TeamManager";
import { IoMdAddCircle, IoMdCreate } from "react-icons/io";
import TeamPercentage from "./TeamPercentage";
export default function OtherTeam({ data }) {
  console.log(data, "jajaja");
  return (
    <div>
      <div className="flex bg-[#e2e2e2] items-center justify-between">
        <div className="flex  justify-center  p-2">
          {data?.logo !== null ? (
            <div className="w-[80px] h-[80px] ">
              <img
                className="flex justify-center"
                alt={data?.team_name}
                src={`${IMG_API}/${data?.logo}`}
              />
            </div>
          ) : (
            <div className="w-[80px] h-[80px] bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full ">
              {data?.name[0]}
            </div>
          )}
          <div className="">
            <div className="text-[#0F3A62] text-center text-[21px]">
              {data?.name} ({data?.abbreviation})
            </div>
            <div className="flex justify-start gap-10 bg-[#e2e2e2] items-center">
              <div className="">
                <div className="text-md text-[#0F3A62] text-center font-semibold ">
                  {data?.win == null ? 0 : data?.win}
                </div>
                <div className="text-[#0F3A62]  font-semibold text-sm">
                  {data?.win <= 1 ? "Win" : "Wins"}
                </div>
              </div>
              <div className="">
                <div className="text-md text-[#0F3A62] text-center  font-semibold ">
                  {data?.draw == null ? 0 : data?.draw}
                </div>
                <div className="text-[#0F3A62]   font-semibold text-sm">
                  {data?.draw <= 1 ? "Draw" : "Draws"}
                </div>
              </div>
              <div className="">
                <div className="text-md text-[#0F3A62] text-center font-semibold ">
                  {data?.loss == null ? 0 : data?.loss}
                </div>
                <div className="text-[#0F3A62]  font-semibold text-sm">
                  {data?.loss <= 1 ? "Loss" : "Losses"}
                </div>
              </div>
              {data?.teampercent_id == null ? (
                <IoMdAddCircle
                  className="text-[#0F3A62] text-xl"
                  //   onClick={HandleCreate}
                />
              ) : (
                <IoMdCreate />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
