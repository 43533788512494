import React, { useEffect, useState } from "react";
import get from "../../features/get";
import { MdModeEdit, MdDelete } from "react-icons/md";
import Search from "../common/Search";
import NoRecord from "../common/NoRecord";
import axios from "axios";
import { API_BASE_URL } from "../../api/endPoint";
import DeleteModal from "../common/DeleteModal";
import EditPosition from "./EditPosition";
import { Tooltip } from "@material-tailwind/react";
import AddPosition from "./AddPostion";

export default function Positions() {
  const data = get?.Getpositions();
  const [pos, setPos] = useState(null);
  const [deletes, setDelete] = useState(false);
  const [update, setUpdate] = useState(false);
  const [create, setCreate] = useState(false);
  const HandleDeleteshow = (e, items) => {
    setDelete(true);
    setPos(items);
  };
  const HandleUpdateshow = (e, items) => {
    setUpdate(true);
    setPos(items);
  };
  const HandleCreateshow = (e, items) => {
    setCreate(true);
  };

  const handleDelete = () => {
    axios
      .put(`${API_BASE_URL}delete/position/${pos?.id}`)
      .then((res) => console.log(res, "back end response"), setDelete(false))
      .catch((err) => console.log(err));
  };

  let positions = [];
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  data?.filter((items) => {
    if (search === "") {
      return positions.push(items);
    } else if (items?.name?.toLowerCase().includes(search)) {
      return positions.push(items);
    }
  });
  console.log(data, "data data");
  return (
    <div className="">
      <div className="flex gap-3 items-baseline mb-3">
        <Search handleSearch={handleSearch} />
        <button
          onClick={HandleCreateshow}
          type="button"
          className=" w-full h-[40px] rounded-md border border-transparent bg-[#0F3A62] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#fff] hover:border-[#0F3A62] hover:text-[#0F3A62] focus:outline-none focus:ring-2 focus:ring-[#0F3A62] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Create Position
        </button>
      </div>

      {positions?.length !== 0 ? (
        <div className="grid grid-cols-12  gap-3">
          {positions?.map((items) => (
            <div className="col-span-4">
              <div className="bg-[#3f9ce82e] shadow-lg w-[310px] ">
                <div className="flex justify-between p-5">
                  <div className="grid gap-3">
                    <div className="text-[12px]">Position</div>
                    <Tooltip className="bg-black" content={items?.description}>
                      <div className="text-[16px]">{items?.name}</div>
                    </Tooltip>
                  </div>
                  <div className="text-[21px] grid gap-3">
                    <MdModeEdit onClick={(e) => HandleUpdateshow(e, items)} />
                    <MdDelete
                      onClick={(e) => HandleDeleteshow(e, items)}
                      className="text-[#ef4444]"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
      {deletes ? (
        <DeleteModal
          modal={setDelete}
          title="Are your sure to delete"
          subTitle={`position ${pos?.name}`}
          action={handleDelete}
        />
      ) : update ? (
        <EditPosition modal={setUpdate} data={pos} />
      ) : create ? (
        <AddPosition modal={setCreate} />
      ) : (
        ""
      )}
    </div>
  );
}
