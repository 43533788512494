import { checkbox } from "@material-tailwind/react";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { API_BASE_URL, IMG_API } from "../../api/endPoint";
import get from "../../features/get";
import Loading from "../common/Loading";

export default function AddMatches({ modal }) {
  function handleClose() {
    modal(false);
  }
  const teams = get?.Getteam();

  const competitors = get?.GetCompetitors();
  const [match_name, setmatch_name] = useState(null);
  const [match_description, setmatch_description] = useState(null);
  const [match_date, setmatch_date] = useState(null);
  const [competitor_id, setcompetitor_id] = useState(null);
  const [team_id, setteam_id] = useState(null);
  const [other_team, setteamOther] = useState(null);
  const [teamcompetitor, setTeamCompetitor] = useState(false);

  let matches = {
    match_name,
    match_description,
    match_date,
    competitor_id: teamcompetitor == true ? null : competitor_id,
    team_id,
    other_team: teamcompetitor == false ? null : other_team,
  };
  const handleCreate = (e) => {
    e.preventDefault();
    axios
      .post(`${API_BASE_URL}create/match/`, matches)
      .then((res) => console.log(res, "back end response"))
      .catch((err) => console.log(err, "error back end response"));
  };
  const teamdata = get?.Getteam();
  const competitordata = get?.GetCompetitors();
  let TeamDetail;
  let GuestTeamDetail;
  let competitorDetail;
  teamdata?.map((items) => (items?.id == team_id ? (TeamDetail = items) : ""));
  teamdata?.map((items) =>
    items?.id == other_team ? (GuestTeamDetail = items) : ""
  );
  competitordata?.map((items) =>
    items?.id == competitor_id ? (competitorDetail = items) : ""
  );
  console.log(teamdata, "other_team");
  const HandleChange = () => {
    setTeamCompetitor(!teamcompetitor);
  };
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={handleClose}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
          >
            <div className="">
              <h1 className="py-2 px-5">Create Matches</h1>
            </div>
            <form onSubmit={handleCreate}>
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                {team_id != null ? (
                  <div className="flex items-center justify-center gap-5">
                    <div className=" p-3">
                      {TeamDetail?.logo !== null ? (
                        <div className="flex justify-center">
                          <img
                            alt={TeamDetail?.team_name}
                            src={`${IMG_API}/${TeamDetail?.logo}`}
                            className="w-[80px] h-[80px] rounded-full"
                          />
                        </div>
                      ) : (
                        <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                          {TeamDetail?.name[0]}
                        </div>
                      )}
                      <div className="gird items-center gap-2 ">
                        <div className="grid">
                          <div className="text-xl font-bold text-[#0F3A62] w-[138px]">
                            {" "}
                            {TeamDetail?.abbreviation}
                          </div>
                          <div className="text-xs text-[#0F3A62]  font-semibold">
                            {TeamDetail?.name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-[30px] text-[#0F3A62]  font-bold">
                      VS
                    </div>
                    {teamcompetitor == false ? (
                      competitor_id != null ? (
                        <div className=" p-3">
                          {competitorDetail?.competitor_logo !== null ? (
                            <div className="flex justify-center">
                              <img
                                alt={competitorDetail?.competitor_name}
                                src={`${IMG_API}/${competitorDetail?.competitor_logo}`}
                                className="w-[80px] h-[80px] rounded-full"
                              />
                            </div>
                          ) : (
                            <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                              {competitorDetail?.competitor_name[0]}
                            </div>
                          )}
                          <div className="gird items-center gap-2 ">
                            <div className="grid">
                              <div className="text-1xl font-bold text-[#0F3A62] w-[138px]">
                                {competitorDetail?.competitor_name}
                              </div>
                              <div className="text-xs text-[#0F3A62]  font-semibold">
                                {competitorDetail?.representative_name}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <Loading />
                      )
                    ) : other_team != null ? (
                      <div className=" p-3">
                        {GuestTeamDetail?.logo !== null ? (
                          <div className="flex justify-center">
                            <img
                              alt={GuestTeamDetail?.team_name}
                              src={`${IMG_API}/${GuestTeamDetail?.logo}`}
                              className="w-[80px] h-[80px] rounded-full"
                            />
                          </div>
                        ) : (
                          <div className="bg-[#0F3A62] text-3xl text-slate-100 flex justify-center items-center  rounded-full w-[80px] h-[80px]">
                            {GuestTeamDetail?.name[0]}
                          </div>
                        )}
                        <div className="gird items-center gap-2 ">
                          <div className="grid">
                            <div className="text-xl font-bold text-[#0F3A62] w-[138px]">
                              {" "}
                              {GuestTeamDetail?.abbreviation}
                            </div>
                            <div className="text-xs text-[#0F3A62]  font-semibold">
                              {GuestTeamDetail?.name}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="flex justify-center">
                  <div class="form-check">
                    <input
                      className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="checkbox"
                      value=""
                      onChange={HandleChange}
                    />
                    <label class="form-check-label inline-block text-gray-800">
                      Team Competitor
                    </label>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-3">
                  <div className="col-span-6">
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Match Name
                    </label>
                    <input
                      value={match_name}
                      onChange={(e) => setmatch_name(e.target.value)}
                      type="text"
                      competitors
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      for="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Match Date
                    </label>
                    <input
                      onChange={(e) => setmatch_date(e.target.value)}
                      type="date"
                      competitors
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required
                    />
                  </div>
                  <div className="col-span-6">
                    <label
                      for="capitan"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {teamcompetitor == false
                        ? "Select Your Team"
                        : "Home Team"}
                    </label>
                    <select
                      onChange={(e) => setteam_id(e.target.value)}
                      id="capitan"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option selected>Choose a Team</option>
                      {teams?.map((items) => (
                        <option
                          disabled={items?.id == other_team ? true : false}
                          value={items?.id}
                        >
                          {items?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {teamcompetitor == false ? (
                    <div className="col-span-6">
                      <label
                        for="Manager"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Select Competitor
                      </label>
                      <select
                        onChange={(e) => setcompetitor_id(e.target.value)}
                        id="Manager"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected>Choose a Competitor</option>
                        {competitors?.map((items) => (
                          <option value={items?.id}>
                            {items?.competitor_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <div className="col-span-6">
                      <label
                        for="Manager"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Guest Team
                      </label>
                      <select
                        onChange={(e) => setteamOther(e.target.value)}
                        id="Manager"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected>Choose a Guest Team</option>
                        {teams?.map((items) => (
                          <option
                            disabled={items?.id == team_id ? true : false}
                            value={items?.id}
                          >
                            {items?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
                <div>
                  <label
                    for="last_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Description
                  </label>
                  <textarea
                    value={match_description}
                    onChange={(e) => setmatch_description(e.target.value)}
                    type="text"
                    id="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Description"
                  >
                    {match_description}
                  </textarea>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-[#67e8f9] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-[#22d3ee] focus:outline-none focus:ring-2 focus:ring-[#22d3ee] focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Create
                </button>
                <button
                  onClick={handleClose}
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
